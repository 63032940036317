// Generated by Framer (1119174)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["KpyMbRWlt"];

const serializationHash = "framer-6gLnO"

const variantClassNames = {KpyMbRWlt: "framer-v-1oo38pr"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const transformTemplate = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, image, width, ...props}) => { return {...props, C_g3vNuAW: image ?? props.C_g3vNuAW ?? {src: "https://framerusercontent.com/images/80vpznA0RBePDPPBOUKniwqoEw.png?scale-down-to=512", srcSet: "https://framerusercontent.com/images/80vpznA0RBePDPPBOUKniwqoEw.png?scale-down-to=512 512w, https://framerusercontent.com/images/80vpznA0RBePDPPBOUKniwqoEw.png?scale-down-to=1024 1024w, https://framerusercontent.com/images/80vpznA0RBePDPPBOUKniwqoEw.png 1056w"}} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, C_g3vNuAW, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "KpyMbRWlt", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-6gLnO", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1oo38pr", className)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"KpyMbRWlt"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-08bf2c81-c18a-419a-8fcb-91ee27a26c81, rgb(65, 65, 65)) /* {\"name\":\"neutral-700\"} */", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", borderBottomLeftRadius: 1000, borderBottomRightRadius: 1000, borderTopLeftRadius: 1000, borderTopRightRadius: 1000, ...style}}><Image background={{alt: "", fit: "fill", sizes: "20px", ...toResponsiveImage(C_g3vNuAW)}} className={"framer-kfm0vw"} data-framer-name={"Thumb"} layoutDependency={layoutDependency} layoutId={"XOOulaE3l"} style={{borderBottomLeftRadius: 1000, borderBottomRightRadius: 1000, borderTopLeftRadius: 1000, borderTopRightRadius: 1000}} transformTemplate={transformTemplate}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-6gLnO [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-6gLnO .framer-10ynz6q { display: block; }", ".framer-6gLnO .framer-1oo38pr { height: 20px; overflow: visible; position: relative; width: 20px; }", ".framer-6gLnO .framer-kfm0vw { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 20px); left: 50%; overflow: visible; position: absolute; top: 50%; width: 20px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 20
 * @framerIntrinsicWidth 20
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"C_g3vNuAW":"image"}
 * @framerImmutableVariables true
 */
const Framerdw0sVMeXZ: React.ComponentType<Props> = withCSS(Component, css, "framer-6gLnO") as typeof Component;
export default Framerdw0sVMeXZ;

Framerdw0sVMeXZ.displayName = "UI — Avatar Thumb [Small]";

Framerdw0sVMeXZ.defaultProps = {height: 20, width: 20};

addPropertyControls(Framerdw0sVMeXZ, {C_g3vNuAW: {__defaultAssetReference: "data:framer/asset-reference,80vpznA0RBePDPPBOUKniwqoEw.png?originalFilename=.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(Framerdw0sVMeXZ, [])